import React from "react";
import { Link } from "react-router-dom";

const blogBaseRoute = ''

const BlogContent = [
	{
		img: "blog_ai_shopping",
		title: `AI and Social Shopping: Revolutionizing the Retail Landscape`,
		animationDealy: "",
		route: "/ai-and-social-shopping"
	},
	{
		img: "blog_influencer",
		title: `Influencer Marketing 101: Leveraging Influencers for Effective Social Selling`,
		animationDealy: "100",
		route: "/influencer-marketing-101"
	},
	{
		img: "blog_social_commerce",
		title: `Understanding the Impact and Evolution of Social Commerce`,
		animationDealy: "200",
		route: "/understanding-the-impact-and-evolution-of-social-commerce"
	},
];

const Blog = () => {
	return (
		<>
			{
				BlogContent.map((val, i) => (
					<div
						className="col-lg-4 col-sm-6"
						data-aos="fade-up"
						data-aos-duration="1200"
						data-aos-delay={val.animationDealy}
						key={i}
					>
						<Link to={`${blogBaseRoute}${val.route}`} className="w-100 d-block">
							<article className="post-meta mt-30">
								<figure className="post-img m0">
									<img
										src={`images/blog/${val.img}.png`}
										alt="blog"
										className="w-100 tran4s"
									/>
								</figure>
								<div className="post-data">
									<h3 className="blog-title">
										<Link to={`${blogBaseRoute}${val.route}`}>{val.title}</Link>
									</h3>
									Continue Reading
								</div>
							</article>
						</Link>
					</div>
				))
			}
		</>
	);
};

export default Blog;
