import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = [
	{
		img: "bananhot",
		url: "",
	},
	{
		img: "nununu",
		url: "",
	},
	{
		img: "adika",
		url: "",
	},
	{
		img: "brenda",
		url: "",
	},
	{
		img: "inter_jeans",
		url: "",
	},
	{
		img: "le_bijou",
		url: "",
	},
	{
		img: "natasha",
		url: "",
	},
	{
		img: "razili",
		url: "",
	},
	{
		img: "story",
		url: "",
	},
	{
		img: "weshoes",
		url: "",
	},
];

const HeroAboutImage = () => {
	const settings = {
		className: "slider variable-width",
		variableWidth: true,
		dots: false,
		arrow: true,
		infinite: true,
		speed: 900,
		slidesToShow: 5,
		slidesToScroll: 1,
		autoplay: true,
		margin: 30,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 2,
				},
			},
		],
	};

	return (
		<Slider {...settings}>
			{LogoSlider.map((val, i) => (
				<div className="item client-logo" key={i} style={{ width: 'none' }}>
					<div className="img-meta d-flex align-items-center justify-content-center" style={{ justifyContent: 'center !important' }}>
						<a href={val.url}>
							<img className="slide-item-image" src={`images/logo/${val.img}.png`} alt="logo" />
						</a>
					</div>
				</div>
			))}
		</Slider>
	);
};

export default HeroAboutImage;
