import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import { environment } from '../../../conf'
import { APPSTORE_SHOPIFY_APP_LINK } from "../../../constants/general.constants";

const logo = "images/logo/logo_desktop.png"
const mobileLogo = "images/logo/logo_mobile.png"
const menuContent = [
	{
		itemName: 'About',
		itemRoute: 'about'
	},
	{
		itemName: 'How it works',
		itemRoute: 'how-it-works'
	},
	{
		itemName: 'For brand',
		itemRoute: 'for-brand'
	},
	{
		itemName: 'Pricing',
		itemRoute: 'pricing'
	},
	{
		itemName: 'FAQ',
		itemRoute: 'faq'
	},
]

// former HeaderLandingAppointment
const HomeHeader = ({
	showSpy = true,
	toggleModal = () => {}
}) => {
	const [click, setClick] = useState(false)
	const handleClick = () => setClick(!click)
	const [navbar, setNavbar] = useState(false)

	const changeBackground = () => {
		if (window.scrollY >= 90) {
			setNavbar(true)
		} else {
			setNavbar(false)
		}
	}

	window.addEventListener("scroll", changeBackground);

	return (
		<>
			<div
				className={
					navbar
						? "theme-main-menu sticky-menu theme-menu-six bg-none fixed"
						: "theme-main-menu sticky-menu theme-menu-six bg-none"
				}
			>
				<div className="d-flex align-items-center">
					<div className="logo">
						<Link to="/">
							<img src={logo} alt="Shopeaks logo" style={{ maxHeight: '40px', aspectRatio: 5 }} />
						</Link>
					</div>

					<div className="right-widget order-lg-3">
						<ul className="d-flex align-items-center">
							<li>
								<a
									href={`${environment.dashboard_url}/admin`}
									className="signIn-action d-flex align-items-center"
								>
									<span>Sign in</span>
								</a>
							</li>
							<li>
								<button className="signup-btn" onClick={() => window.open(APPSTORE_SHOPIFY_APP_LINK,'_blank')}>
									<span>Start for free</span>
								</button>
							</li>
						</ul>
					</div>

					<nav
						id="mega-menu-holder"
						className="navbar navbar-expand-lg ms-lg-auto me-lg-auto order-lg-2"
					>
						<div className="container nav-container">
							<div className="mob-header">
								<button className="toggler-menu" onClick={handleClick}>
									<div className={click ? "active" : ""}>
										<span></span>
										<span></span>
										<span></span>
									</div>
								</button>
							</div>

							{
								showSpy && <div
									className="navbar-collapse collapse landing-menu-onepage"
									id="navbarSupportedContent"
								>
									<div className="d-lg-flex justify-content-between align-items-center">
										<Scrollspy
											className="navbar-nav main-side-nav font-gordita"
											items={menuContent.map(menuItem => menuItem.itemRoute)}
											currentClassName="active"
											offset={-90}
										>
											{
												menuContent.map(menuItem => <li key={menuItem.itemName + menuItem.itemRoute} className="nav-item">
													<a href={'#' + menuItem.itemRoute} className="nav-link" style={{ color: 'black', fontSize: '16px', textTransform: 'none' }}>
														{menuItem.itemName}
													</a>
												</li>)
											}
										</Scrollspy>
									</div>
								</div>
							}
						</div>
					</nav>
				</div>
			</div>

			<div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
				<div className="logo order-md-1">
					<Link to="/project-management">
						<img src={logo} alt="brand" style={{ maxHeight: '35px', aspectRatio: 5 }}/>
					</Link>
					<div className="fix-icon text-dark" onClick={handleClick}>
						<img src="images/icon/close.svg" alt="icon" />
					</div>
				</div>

				<Scrollspy
					className="navbar-nav"
					id="theme-menu-list"
					items={menuContent.map(menuItem => menuItem.itemRoute)}
					currentClassName="active"
					offset={-90}
				>
					{
						menuContent.map(menuItem => <li key={menuItem.itemName + menuItem.itemRoute} className="nav-item">
							<a href={'#' + menuItem.itemRoute} className="nav-link" onClick={handleClick}>
								{menuItem.itemName}
							</a>
						</li>)
					}
				</Scrollspy>
			</div>
		</>
	);
};

export default HomeHeader;
