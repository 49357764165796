import React, { useState } from "react";
import { Helmet } from "react-helmet";
import HomeCopyRight from "../../../components/footer/HomeCopyRight";
import HomeFooter from "../../../components/footer/HomeFooter";
import HomeHeader from "../../../components/header/landing/HomeHeader";
import ModalStartNowForm from "../../../components/modal-form/ModalStartNowForm";
import HomeBlogContent from "../../../components/blog/HomeBlogContent";

const HomeBlog = () => {
	const [isModalOpen, setModalOpen] = useState(false)

	function toggleModalOne() {
		setModalOpen(!isModalOpen);
	}

	return (
		<div className="main-page-wrapper">
			<Helmet>
				<title>Shopeaks Shop Blog</title>
			</Helmet>
			{/* End Page SEO Content */}
			<HomeHeader showSpy={false} toggleModal={toggleModalOne} />
			{/* End Header */}

			{/* 	=============================================
            Fancy Hero One
        ==============================================  */}
			<div className="fancy-hero-one">
				<div className="container">
					<div className="row">
						<div className="col-xl-9 col-lg-10 m-auto">
							<h2 className="font-gilroy-bold">
								Social Commerce Blog
							</h2>
						</div>
						<div className="col-lg-9 m-auto">
							<p className="font-rubik">
								Useful insights, powerful tips and more to grow with social commerce.
							</p>
						</div>
					</div>
				</div>
				<div className="bubble-one"></div>
				<div className="bubble-two"></div>
				<div className="bubble-three"></div>
				<div className="bubble-four"></div>
				<div className="bubble-five"></div>
				<div className="bubble-six"></div>
			</div>
			{/* /.fancy-hero-one */}

			{/* =====================================================
            Feature Blog One
        ===================================================== */}
			<div className="feature-blog-one blog-page-bg">
				<div className="shapes shape-one"></div>
				<div className="shapes shape-two"></div>
				<div className="shapes shape-three"></div>
				<div className="container">
					<div className="row">
						<HomeBlogContent />
					</div>
					{/* End .row */}
				</div>
			</div>
			{/* /.feature-blog-one */}

			<footer className="theme-footer-eight mt-100">
				<div className="top-footer">
					<div className="container">
						<HomeFooter />
					</div>
				</div>
				<div className="container">
					<div className="bottom-footer mt-50 md-mt-30">
						<HomeCopyRight />
					</div>
				</div>
			</footer>
			{/* /.theme-footer-one */}
			<ModalStartNowForm isOpen={isModalOpen} toggleModal={toggleModalOne} />
		</div>
	);
};

export default HomeBlog;
