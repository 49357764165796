


import { request } from "../services/RequestWrapper";
import { environment } from '../conf';
import { CUSTOMER_REGISTER_API_ROUTE } from "../constants/api.constants";

export function customerRegisterAPI(name, email, description) {
    return new Promise((resolve, reject) => {
        const options = {
            method: 'POST',
            body: {
                name,
                email,
                description
            }
        }

        const url = `${environment.base_url}${CUSTOMER_REGISTER_API_ROUTE}`
        request(url, options)
            .then(response => {
                if (response.status) {
                    resolve(response.data)
                } else {
                    reject(response.error)
                }
            })
            .catch(error => {
                reject(error)
            })
    })
}