import React, { useEffect } from "react";
import { environment } from '../../conf'

const Dashboard = () => {
	// For header select menu
	useEffect(() => {
		window.location.href = `${environment.dashboard_url}${window.location.pathname}`
	}, [])

	return <></>	
};

export default Dashboard;
