import React from "react";
import PricingMonthly from "./PricingMonthly";

const HomePricing = ({
	toggleModal = () => {}
}) => {
	return (
		<div className="pricing-table-area-four">
			<PricingMonthly toggleModal={toggleModal}/>
		</div>
	);
};

export default HomePricing;
