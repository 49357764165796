import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioImages = [
	{
		img: "client_feedback_1",
		altName: "Screen 1",
	},
	{
		img: "client_feedback_2",
		altName: "Screen 2",
	},
	{
		img: "bananhot_america_feedback",
		altName: "Screen 3",
	},
	{
		img: "client_feedback_4",
		altName: "Screen 4",
	}
];

const Portfolio = () => {
	const settings = {
		dots: false,
		draggable: false,
		arrow: true,
		infinite: false,
		speed: 900,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					dots: true,
				},
			},
		],
	};

	return (
		<>
			<Slider {...settings}>
				{PortfolioImages.map((val, i) => (
					<div className="item clients-feedback" key={i}>
						<div className="img-meta">
							<img src={`images/assets/clients_feedback/${val.img}.png`} alt={val.altName} />
						</div>
					</div>
				))}
			</Slider>
		</>
	);
};

export default Portfolio;
