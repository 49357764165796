import React, { useState } from "react";
import HomeHeader from "../../../components/header/landing/HomeHeader";
import HomeFooter from "../../../components/footer/HomeFooter";
import HomeCopyRight from "../../../components/footer/HomeCopyRight";
import ModalStartNowForm from "../../../components/modal-form/ModalStartNowForm";

const BlogDetails = ({ content }) => {
	const [isModalOpen, setModalOpen] = useState(false)

	function toggleModalOne() {
		setModalOpen(!isModalOpen)
	}

	return (
		<div className="main-page-wrapper p0">
			<HomeHeader showSpy={false} toggleModal={toggleModalOne} />

			<div className="blog-page-bg">
				<div className="container">
					<div className="row">
						<div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
							<div className="post-meta">
								{content}
							</div>
						</div>
					</div>
				</div>
			</div>

			<footer className="theme-footer-eight pt-130 md-pt-70">
				<div className="top-footer">
					<div className="container">
						<HomeFooter />
					</div>
				</div>

				<div className="container">
					<div className="bottom-footer-content">
						<HomeCopyRight />
					</div>
				</div>
			</footer>
			<ModalStartNowForm isOpen={isModalOpen} toggleModal={toggleModalOne} />
		</div>
	);
};

export default BlogDetails;
