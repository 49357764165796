import React from "react";
import { environment } from '../../conf'

const HomeFooter = () => {
	return (
		<div className="row justify-content-end">
			<div className="col-xl-6 col-lg-6 col-md-12 col-12 footer-about-widget">
				<div className="logo">
					<a href="/">
						<img src="images/logo/logo_desktop.png" alt="" style={{ maxHeight: '40px', aspectRatio: 5 }}/>
					</a>
				</div>
				<ul className="font-rubik mt-10">
					<li>
						<a href="mailto:sales@shopeaks.com">sales@shopeaks.com</a>
					</li>
					<li>
						<a href="tel:+972 54-434-4354" className="phone">
							+972 54-434-4354
						</a>
					</li>
				</ul>
				<img src="images/assets/shopify_shopifyplus_image.svg" style={{ width: 'fit-content', height: '80px' }} />
			</div>
			<div className="col-xl-3 col-lg-3 col-md-6 footer-list">
				<h5 className="footer-title">About</h5>
				<ul>
					<li>
						<a href="/#about">Home</a>
					</li>
					<li>
						<a href="/#how-it-works">How it works</a>
					</li>
					<li>
						<a href="/#for-brand">For brand</a>
					</li>
					<li>
						<a href="/#pricing">Pricing</a>
					</li>
					<li>
						<a href="/#faq">FAQ</a>
					</li>
				</ul>
			</div>
			<div className="col-xl-3 col-lg-3 col-md-6 footer-list">
				<h5 className="footer-title">Legal</h5>
				<ul>
					<li>
						<a href='/terms'>Terms & conditions</a>
					</li>
					<li>
						<a href='/privacy'>Privacy policy</a>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default HomeFooter;
