import React from "react";
// Route Specific
import { Routes, Route, Navigate } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

// All HOME PAGE ROUTES
// import EventOrganizer from "../views/all-home-pages/EventOrganizer";
// import DocLanding from "../views/all-home-pages/DocLanding";
// import DocSignatureLanding from "../views/all-home-pages/DocSignatureLanding";
// import ProjectManagement from "../views/all-home-pages/ProjectManagement";
// import CustomerSupport from "../views/all-home-pages/CustomerSupport";
// import ProductLanding from "../views/all-home-pages/ProductLanding";
// import ProductLandingDark from "../views/all-home-pages/ProductLandingDark";
// import NoteTakingLanding from "../views/all-home-pages/NoteTakingLanding";
// import VideoEditorLanding from "../views/all-home-pages/VideoEditorLanding";git
// import AppointmentScheduling from "../views/all-home-pages/AppointmentScheduling";
// import MobileAppLanding from "../views/all-home-pages/MobileAppLanding";
// import WebsiteBuilderLanding from "../views/all-home-pages/WebsiteBuilderLanding";
// import Ecommerce from "../views/all-home-pages/Ecommerce";
// import ComingSoon from "../views/all-home-pages/ComingSoon";

// All INNER PAGES ROUTES START FROM BELLOW

// PAGES DROPDOWN ALL ROUTES
// Team inner pages
// import Team1 from "../views/inner-pages/pages/team/Team1";
// import Team2 from "../views/inner-pages/pages/team/Team2";
// import Team3 from "../views/inner-pages/pages/team/Team3";
// import Team4 from "../views/inner-pages/pages/team/Team4";
// import Team5 from "../views/inner-pages/pages/team/Team5";
// import Team6 from "../views/inner-pages/pages/team/Team6";
// import TeamDetailsV1 from "../views/inner-pages/pages/team/TeamDetailsV1";
// import TeamDetailsV2 from "../views/inner-pages/pages/team/TeamDetailsV2";
// import Faq from "../views/inner-pages/pages/Faq";
// import FaqDetails from "../views/inner-pages/pages/FaqDetails";

// Contact us inner pages
// import ContactCustomerSupport from "../views/inner-pages/pages/contact/ContactCustomerSupport";
// import ContactEventOrganizer from "../views/inner-pages/pages/contact/ContactEventOrganizer";
// import ContactProjectManagement from "../views/inner-pages/pages/contact/ContactProjectManagement";
// import ContactDocumentation from "../views/inner-pages/pages/contact/ContactDocumentation";

// About us inner pages
// import AboutCustomerSupport from "../views/inner-pages/pages/about/AboutCustomerSupport";
// import AboutEventOrganizer from "../views/inner-pages/pages/about/AboutEventOrganizer";
// import AboutProjectManagement from "../views/inner-pages/pages/about/AboutProjectManagement";
// import AboutDocumentation from "../views/inner-pages/pages/about/AboutDocumentation";

// Pricing inner pages
// import PricingCustomerSupport from "../views/inner-pages/pages/pricing/PricingCustomerSupport";
// import PricingEventOrganizer from "../views/inner-pages/pages/pricing/PricingEventOrganizer";
// import PricingProjectManagement from "../views/inner-pages/pages/pricing/PricingProjectManagement";

// FEATURES DROPDOWN ALL ROUTES
// import Login from "../views/inner-pages/features/miscellaneous/Login";
// import SignUp from "../views/inner-pages/features/miscellaneous/SignUp";
// import TermsConditions from "../views/inner-pages/features/miscellaneous/TermsConditions";
// import SolutionMangement from "../views/inner-pages/features/SolutionMangement";
// import ProductCustomerSupport from "../views/inner-pages/features/ProductCustomerSupport";
// import FeaturesCustomerSupport from "../views/inner-pages/features/FeaturesCustomerSupport";

// SERVICE PAGES ROUTES
// import ServiceV1 from "../views/inner-pages/service/ServiceV1";
// import ServiceV2 from "../views/inner-pages/service/ServiceV2";
// import ServiceV3 from "../views/inner-pages/service/ServiceV3";
// import ServiceV4 from "../views/inner-pages/service/ServiceV4";
// import ServiceDetails from "../views/inner-pages/service/ServiceDetails";

// DOCS DROPDOWN ALL ROUTES
// import DocFullWidth from "../views/inner-pages/docs/DocFullWidth";
// import DocFullWidthBanner from "../views/inner-pages/docs/DocFullWidthBanner";
// import DocBox from "../views/inner-pages/docs/DocBox";
// import DocBoxWithBanner from "../views/inner-pages/docs/DocBoxWithBanner";
// import Changelog from "../views/inner-pages/docs/Changelog";

// PORTFOLIO DROPDOWN ALL ROUTES
// import PortfolioV1 from "../views/inner-pages/portfolio/PortfolioV1";
// import PortfolioV2 from "../views/inner-pages/portfolio/PortfolioV2";
// import PortfolioV3 from "../views/inner-pages/portfolio/PortfolioV3";
// import PortfolioV4 from "../views/inner-pages/portfolio/PortfolioV4";
// import PortfolioV5 from "../views/inner-pages/portfolio/PortfolioV5";
// import PortfolioDetailsV1 from "../views/inner-pages/portfolio/PortfolioDetailsV1";

// BLOGS DROPDOWN ALL ROUTES
// import BlogV1 from "../views/inner-pages/blog-pages/BlogV1";
// import BlogV2 from "../views/inner-pages/blog-pages/BlogV2";
// import BlogV3 from "../views/inner-pages/blog-pages/BlogV3";
// import BlogV4 from "../views/inner-pages/blog-pages/BlogV4";
// import BlogV5 from "../views/inner-pages/blog-pages/BlogV5";
// import BlogV6 from "../views/inner-pages/blog-pages/BlogV6";

// Not Found Page
import NotFound from "../views/NotFound";

// import FormSurveyLanding from "../views/all-home-pages/FormSurveyLanding";
// import VrLanding from "../views/all-home-pages/VrLanding";
// import Cart from "../views/inner-pages/e-commerce/Cart";
// import Checkout from "../views/inner-pages/e-commerce/Checkout";
// import ProductDetails from "../views/inner-pages/e-commerce/ProductDetails";
import Home from "../views/all-home-pages/Home";
import BlogDetails from "../views/inner-pages/blog-pages/BlogDetails";
import Dashboard from "../views/route-to-dashboard/Dashboard";
import PrivacyPolicyPage from "../components/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "../components/TermsAndConditionsPage/TermsAndConditionsPage";
import HomeBlog from "../views/inner-pages/blog-pages/HomeBlog";
import BlogRoutes from "./BlogRoutes";

const AllRoutes = () => {
	return (
		<>
			<ScrollTopBehaviour />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/terms" element={<BlogDetails content={<TermsAndConditionsPage />} />} />
				<Route path="/privacy" element={<BlogDetails content={<PrivacyPolicyPage />} />} />
				<Route path="/blog" element={<HomeBlog />} />
				{BlogRoutes}
				
				{/* Redirect home */}
				<Route path="/thank-you" element={<Navigate to="/" replace />} />

				{/* NotFound Route */}
				<Route path="/admin" element={<Dashboard />} >
					<Route path="*" element={<Dashboard />} />
				</Route>

				{/* NotFound Route */}
				<Route path="*" element={<NotFound />} />
			</Routes>
		</>
	)
}

export default AllRoutes
