const debug = {
    response_language: "english",
    base_url: "http://127.0.0.1:5001",
    dashboard_url: "http://127.0.0.1:3000/",
}

const dev = {
    base_url: `https://api-dev.payli.in`,
    dashboard_url: `https://admin-dev.shopeaks.com`
}

const prod = {
    base_url: `https://api.payli.in`,
    dashboard_url: `https://admin.shopeaks.com`
}

export const environment = (() => {
    switch (process.env.REACT_APP_STAGE) {
        case "debug":
            return debug;
        case "dev":
            return dev;
        case "prod":
            return prod;
        default:
            return null;
    }
})()