import React from "react";

const Social = () => {
  return (
    <ul className="clearfix">
      <li data-aos="fade-up" data-aos-duration="1200">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/facebook.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/gmail.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/instagram.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/line.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/linkedin.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/snapchat.png" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/telegram.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/threads.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/tik-tok.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/X.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="600">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/whatsapp.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/youtube.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="700">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/pinterest.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/tumblr.svg" alt="logo" />
        </div>
      </li>

      <li data-aos="fade-up" data-aos-duration="1200" data-aos-delay="800">
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/we-chat.svg" alt="logo" />
        </div>
        <div className="icon-box d-flex align-items-center justify-content-center">
          <img src="images/assets/social_media_logos/vimeo.svg" alt="logo" />
        </div>
      </li>
    </ul>
  );
};

export default Social;
