import React from "react";

const SmartStoreVideo = () => {
  return <video
        autoPlay={`${true}`}
        muted={`${true}`}
        playsInline={`${true}`}
        loop={`${true}`}
      >
        <source 
          src="https://cdn.paylink.cloudfront.payli.in/application_resources/smart_store_video.mp4" 
          type="video/mp4"
          poster="https://cdn.paylink.cloudfront.payli.in/application_resources/smart_store_video.png"
        />
      </video>
};

export default SmartStoreVideo;
