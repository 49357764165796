import React from "react";
import Tilt from "react-parallax-tilt";

const HeroBannerForBrand = () => {
	return (
		<div className="hero-banner-twelve lg-container" id="for-brand" style={{ backgroundColor: 'white' }}>
			<div className="container for-brand">
				<div className="row">
					<div className="col-xl-11 col-lg-12 col-md-10 m-auto text-center">
						<h1 className="hero-heading font-gordita" data-aos="fade-up">
							<span style={{ color: "#FF006B" }}>All the data</span>{" "}
							<span style={{ color: "#FEDC00" }}>you need</span>
						</h1>
					</div>
				</div>

				<Tilt>
					<div className="screen-holder" data-aos="fade-up">
						<img
							src="images/assets/for_brand_main.svg"
							alt="screen"
							className="img-meta"
						/>
						<img
							src="images/assets/attachment1.png"
							alt="screen"
							className="shapes screen-one"
						/>
						<img
							src="images/assets/attachment2.png"
							alt="screen"
							className="shapes screen-two"
						/>
						<img
							src="images/shape/230.svg"
							alt="screen"
							className="shapes shape-one"
						/>
						<img
							src="images/shape/231.svg"
							alt="screen"
							className="shapes shape-two"
						/>
						{/* </div> <!-- /.screen-holder --> */}
					</div>
				</Tilt>

				<img
					src="images/shape/232.svg"
					alt="shape"
					className="shapes shape-three"
				/>
				<img
					src="images/shape/233.svg"
					alt="shape"
					className="shapes shape-four"
				/>
				<img
					src="images/shape/234.svg"
					alt="shape"
					className="shapes shape-five"
				/>
				<img
					src="images/shape/235.svg"
					alt="shape"
					className="shapes shape-six"
				/>
				<img
					src="images/shape/236.svg"
					alt="shape"
					className="shapes shape-seven"
				/>
				<img
					src="images/shape/232.svg"
					alt="shape"
					className="shapes shape-eight"
				/>
			</div>
		</div>
	);
};

export default HeroBannerForBrand;
