import React from "react";
import { Link } from "react-router-dom";

const blogBaseRoute = ''

const BlogContent = [
	{
		img: "blog_ai_shopping",
		tag: "Technology",
		title: `AI and Social Shopping: Revolutionizing the Retail Landscape`,
		routePath: "/ai-and-social-shopping",
		dealyAnimation: "",
	},
	{
		img: "blog_influencer",
		tag: "Software",
		title: `Influencer Marketing 101: Leveraging Influencers for Effective Social Selling`,
		routePath: "/influencer-marketing-101",
		dealyAnimation: "50",
	},
	{
		img: "blog_social_commerce",
		tag: "Blog",
		title: `Understanding the Impact and Evolution of Social Commerce`,
		routePath: "/understanding-the-impact-and-evolution-of-social-commerce",
		dealyAnimation: "100",
	}
];

const HomeBlogContent = () => {
	return (
		<>
			{BlogContent.map((item, i) => (
				<div
					className="col-lg-4 col-md-6"
					data-aos="fade-up"
					data-aos-duration="1200"
					key={i}
					data-aos-delay={item.dealyAnimation}
				>
					<div className="post-meta">
						<img
							src={`images/blog/${item.img}.png`}
							alt="media"
							className="image-meta"
						/>
						<div className="tag">{item.tag}</div>
						<h3>
							<Link to={`${blogBaseRoute}${item.routePath}`} className="title">
								{item.title}
							</Link>
						</h3>
						<Link
							to={`${blogBaseRoute}${item.routePath}`}
							className="read-more d-flex justify-content-between align-items-center"
						>
							<span>Read More</span>
							<i className="flaticon-right-arrow"></i>
						</Link>
					</div>
					{/* /.post-meta */}
				</div>
			))}
		</>
	);
};

export default HomeBlogContent;
