import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";

const App = () => {
	useEffect(() => {
		AOS.init({
			duration: 1200,
		});
	}, []);
	window.addEventListener("load", AOS.refresh);

	return (
		<>
			<Helmet>
				<title>Shopeaks - Social Commerce Platform</title>
				<meta property="og:site_name" content="shopeaks" />
				<meta
					property="og:url"
					content="https://shopeaks.com"
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:title"
					content="Shopeaks: Social Commerce Platform & Commerce Platform"
				/>
				<meta
					name="keywords"
					content="social, social commerce, social Commerce platform, shopify commerce, increase conversion rate, increase order value, social shopping, social tools, ecommerce, shopify plus, growth social commerce, Social commerce platform, AI-powered technology, Personalized stores, E-commerce integration, Data-driven analytics, Conversion rate optimization, Influencer marketing, Real-time sales, Plug-and-play application, Social media trends, Influencer performance analysis, Higher average order value, Integrated e-commerce platforms, Efficient sales management, Social media optimization, Advanced AI algorithms, Personalized shopping experience, Comprehensive data analysis, Sales empowerment, Elite intelligence corps expertise"
				/>
				<meta
					name="description"
					content="Shopeaks is an AI-powered social commerce platform for e-commerce shops, empowering sales across all social media platforms. Increase CR by 5X and higher AOV by 30%"
				/>
				<meta name="description" content="Social Commerce Platform & Influencers' Revenue Maximizer" />
			</Helmet>
			{/* End Seo Helmt */}

			<ScrollToTop />
			{/* End Scroll top */}

			<AllRoutes />
		</>
	);
};

export default App;
