import { Route } from "react-router-dom"
import BlogContent1 from "../views/inner-pages/blog-pages/BlogContent1"
import BlogContent2 from "../views/inner-pages/blog-pages/BlogContent2"
import BlogContent3 from "../views/inner-pages/blog-pages/BlogContent3"
import BlogDetails from "../views/inner-pages/blog-pages/BlogDetails"

const BlogRoutes = [
    <Route path="/ai-and-social-shopping" key="ai-and-social-shopping" element={<BlogDetails content={<BlogContent1 />} />} />,
    <Route path="/influencer-marketing-101" key="influencer-marketing-101" element={<BlogDetails content={<BlogContent2 />} />} />,
    <Route path="/understanding-the-impact-and-evolution-of-social-commerce" key="understanding-the-impact-and-evolution-of-social-commerce" element={<BlogDetails content={<BlogContent3 />} />} />
]

export default BlogRoutes