import React from "react";
import { Link } from "react-router-dom";
import HeroAboutImage from "../brand/HeroAboutImage";
import Tilt from "react-parallax-tilt";
import { environment } from '../../conf'
import { APPSTORE_SHOPIFY_APP_LINK } from "../../constants/general.constants";

const HeroAbout = ({
	toggleModal = () => {}
}) => {
	return (
		<div className="hero-banner-nine lg-container">
			<div className="container">

				<div className="row">
					<div className="col-lg-6">
						<h1 className="hero-heading">
							Grow, engage, and sell more on social media!
						</h1>
						<p className="hero-sub-heading">
							Automatically turn every post into a personalized store for each user. Increase conversion up to 5X and average order value up to 30%.
						</p>
						<button
							onClick={() => window.open(APPSTORE_SHOPIFY_APP_LINK,'_blank')}
							target='_blank'
							className="theme-btn-five"
							data-aos="fade-right"
							data-aos-duration="1200"
							data-aos-delay="200"
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '5px',
								alignItems: 'start',
								width: 'fit-content',
								padding: '0px 50px 0px 20px',
								background: 'linear-gradient(to right, #d700d7, #e59604)',
								borderRadius: '10px'
							}}
						>
							<h4 style={{ color: '#e1e1e1', padding: '15px', color: 'white' }}>Start now</h4>
						</button>
						<p className="term-text">
							<button style={{ color: 'black', textDecoration: 'underline' }} onClick={() => window.open('https://calendly.com/paylinkshop/15-min','_blank')} >Book a demo</button>
						</p>
						<div style={{
							height: '1px',
							width: '90%',
							backgroundColor: '#cccccc',
							marginTop: '20px'
						}} />
						<img src="images/assets/shopify_shopifyplus_image.svg" style={{ width: 'fit-content', height: '80px' }} />
					</div>
					<div className="illustration-container col-lg-6">
						<Tilt>
							<img src="images/assets/about.svg" alt="screen" />
						</Tilt>
					</div>
				</div>
			</div>

			<div className="partner-slider-two md-mt-25 mb-30">
				<div className="container">
					<div className="partnerSliderTwo">
						<HeroAboutImage />
					</div>
				</div>
			</div>
		</div>
	);
};

export default HeroAbout;
