import React from "react";
import { APPSTORE_SHOPIFY_APP_LINK } from "../../../constants/general.constants";

const PricingContent = [
  // {
  //   packName: "Lite",
  //   packDetails: "Free for experimenting the best way to create sales in social media",
  //   bgColor: "#EDFFEB",
  //   price: "Free",
  //   durationNumber: "",
  //   facility: "",
  //   features: [
  //     "Forever Free",
  //     "Trackable Shorts links",
  //     "Shopboard - Link-in-bio",
  //     "Sync every 24 hours",

  //   ],
  //   animationDelay: "",
  //   activeClass: "",
  //   trialText:"No card required, cancel any time",
  // },
  {
    packName: "Core",
    packDetails: "Ideal for entry-level stores with at least 30 monthly sales.",
    bgColor: "#FFF7EB",
    price: "$49.9",
    durationNumber: "monthly",
    facility: "+ $1/transaction",
    features: [
      "Everything in Lite",
      "Instant-Store links",
      "Advanced analytics",
      "Customization",
      "Live inventory",
      "Limited Influencers platform",
    ],
    animationDelay: "",
    activeClass: "",
    trialText:"No card required, cancel any time",
  },
  {
    packName: "Pro",
    packDetails: "Ideal for growing businesses looking to maximize revenue.",
    bgColor: "#E2F2FD",
    price: "$199.9",
    durationNumber: "monthly",
    facility: "+ $0.75/transaction",
    features: [
      "Everything in Core",
      "Influencers platform",
      "Personalized onboarding",
      "Priority support",
      "Phone Support",
    ],
    animationDelay: "100",
    activeClass: "",
    trialText:"No card required, cancel any time",
  },
  {
    packName: "Enterprise",
    packDetails: "Ideal for well-established stores with a desire for a customized experience.",
    bgColor: "#FFEBEB",
    price: "Let's talk",
    durationNumber: "",
    facility: "",
    features: [],
    animationDelay: "200",
    activeClass: "",
    trialText:"",
  },
];

const PricingMonthly = ({
	toggleModal = () => {}
}) => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeClass}`}>
            <div className="pack-name">{val.packName}</div>
            <div className="pack-details">{val.packDetails}</div>
            <div
              className="top-banner d-md-flex"
              style={{ background: val.bgColor }}
            >
              <div className="price">
                {val.price}
              </div>
              <div>
                <span>{val.durationNumber}</span>
                <em>{val.facility}</em>
              </div>
            </div>
            {/* /.top-banner */}
            <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <button className="trial-button" onClick={() => window.open(APPSTORE_SHOPIFY_APP_LINK,'_blank')}>
              Start 30 days free trial
            </button>
            <div className="trial-text">{val.trialText}</div>
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
